

@media (min-width: 768px) { 

	body {
		overflow-x: hidden;
	}

	.page-height {
		overflow-y: auto;
		overflow-x: hidden;
		min-height: calc(100vh - 225px);
		max-height: calc(100vh - 225px);
	}
}
